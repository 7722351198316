/* navstyle.css */
.custom-navbar {
    background-color: #e98074;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  }
  
  .custom-navbar .nav-link,
  .custom-navbar .navt {
    color: rgb(12, 2, 2) !important;
    font-weight: 900;
    font-size: 15px;
    transition: color 0.3s ease;
  }
  
  .custom-navbar .nav-link:hover,
  .custom-navbar .navt:hover {
    color: #f5d9b2 !important;
  }
  
  .custom-navbar .nav-link.text-center,
  .custom-navbar .navt.text-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 50px;
  }
  
  .custom-navbar .nav-dropdown .dropdown-menu {
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .custom-navbar .nav-dropdown .dropdown-menu .dropdown-item {
    color: #333;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .custom-navbar .nav-dropdown .dropdown-menu .dropdown-item:hover {
    background-color: #f8f9fa;
    color: #f5d9b2;
  }
  
  /* Navbar toggle customization */
  .custom-navbar .navbar-toggler {
    border-color: transparent;
  }
  
  .custom-navbar .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=UTF8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-width='2' linecap='round' linejoin='round' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  