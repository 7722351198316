

.glyphicon {
    margin-right: 4px !important; /*override*/
}

.pagination .glyphicon {
    margin-right: 0px !important; /*override*/
}

.pagination a {
    color: #555;
    background: #e5e5e5;
    padding: 6px;
}

.panel ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
}

    .panel ul.demo1 {
        height: 284px !important;
    }

.news-item {
    padding: 4px 4px;
    margin: 0px;
    border-bottom: 1px dotted #555;
}

#jquery-script-menu {
    position: fixed;
    height: 90px;
    width: 100%;
    top: 0;
    left: 0;
    border-top: 5px solid #316594;
    background: #fff;
    -moz-box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.16);
    z-index: 999999;
    padding: 10px 0;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.jquery-script-center {
    width: 960px;
    margin: 0 auto;
}

    .jquery-script-center ul {
        width: 212px;
        float: left;
        line-height: 45px;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .jquery-script-center a {
        text-decoration: none;
    }

.jquery-script-ads {
    width: 728px;
    height: 90px;
    float: right;
}

.jquery-script-clear {
    clear: both;
    height: 0;
}

.panel {
    background: rgb(255, 255, 255);
}

.notification {
    /*position: absolute;*/
    z-index: 8;
    left: 1255px;
    top: 230px;
}

    .notification .panel.panel-default {
        border: 2px solid #e98074 !important;
    }

        .notification .panel.panel-default .panel-heading {
            background-color: #e98074 !important;
            border: 1px solid #e98074 !important;
            color: #fff !important;
            padding: 3px 15px !important;
        }

            .notification .panel.panel-default .panel-heading i {
                color: #fff !important;
            }

        .notification .panel.panel-default .panel-footer {
            background-color: #e98074 !important;
            border: 1px solid #e98074 !important;
            padding: 3px 15px !important;
        }

            .notification .panel.panel-default .panel-footer .pagination > li > a, .pagination > li > span {
                padding: 2px 8px !important;
            }

    .notification i {
        color: #ed2a28;
    }

    .notification ul li i {
        padding-right: 10px;
    }

    .notification ul li a {
        display: block;
        color: #ed2a28;
    }

    .notification ul li {
        font-size: 13px;
    }

.not {
    margin-left: -37px;
    margin-right: 71px;
    margin-top: -26px;
}


.panel-heading {
    /* background: #e98074 !important; */
    color: black !important;
    border-radius: 6px !important;
    padding: 4px !important;
}

.panel-default {
    border-color: #e98074;
}


.panel {
    margin-bottom: 20px;
    background-color: #efefef;
    border: 1px solid #e98074;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px #ffcec9;
}


.panel-body {
    padding: 15px;
}


.panel-footer {
    padding: 10px 15px;
    background-color: #e5e5e5;
    border-top: 1px solid #ddd;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
